<template>
  <div>
    <Modal :options="{width: '50vw'}" @close="$emit('close')">
      <div class="title">Agregar evidencia</div>
      <div class="body">
        <div class="row form-group">
          <div class="col-sm-9">
            <div class="input-group">
              <div class="form-control text-truncate" ref="seleccionar_archivo_nombre"></div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="$refs.seleccionar_archivo.click()">Seleccionar documento</button>
              </div>
            </div>
            <input class="seleccionar_archivo" type="file" name="seleccionar_archivo" id="seleccionar_archivo" ref="seleccionar_archivo" @change="obtener_nombre">
          </div>
          <div class="col-sm-3"><button class="btn btn-primary" @click="agregar_archivo">Agregar evidencia</button></div>
        </div>

        <div class="row form-group">
          <div v-for="(evidencia,index) in evidencias" class="col-sm-4 mb-2">
            <div class="card">
              <div class="card-img-top">
                <span class="helper"></span>
                <img :id="'card_tmp_id_'+index" :src="imagen_default(index,evidencia)" alt="">
              </div>
              <div class="card-body">
                <h5 class="card-title text-truncate">{{ evidencia.name }}</h5>
                <div class="card-text">
                  <div>Tipo: {{ obtener_extension(evidencia) }}</div>
                  <div>Peso: {{ $helper.bytesConverted(evidencia.size) }}</div>
                </div>
                <div class="card-text text-right">
                  <small class="text-muted">
                    <a href="#" @click.prevent="eliminar_archivo(index)">Eliminar</a>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="cargar_archivos">Subir documentos</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
      ,incidencia: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
    }
    ,data() {
      return {
        evidencias: []
      }
    }
    ,methods: {
      imagen_default(index, evidencia) {
        let imagenes = ['png','jpeg','jpg','gif','bmp'];

        if (imagenes.indexOf(this.obtener_extension(evidencia)) != -1) {
          let reader = new FileReader();
          reader.onload = event => {
            document.getElementById('card_tmp_id_'+index).setAttribute('src',event.target.result);
          }

          reader.readAsDataURL(evidencia);
          return;
        }

        switch(process.env.VUE_APP_ENV) {
          case 'local':
            return 'http://formalizacionbpb.local/storage/images/documents.png';
            break;
          case 'test':
            return 'https://formalizacion.bienparabien.dev/storage/images/documents.png';
            break;
          case 'production':
            return 'https://formalizacion.bienparabien.com/storage/images/documents.png';
            break;
        }
      }
      ,obtener_nombre() {
        if (this.$refs.seleccionar_archivo.files.length > 0)
          this.$refs.seleccionar_archivo_nombre.innerHTML = this.$refs.seleccionar_archivo.files[0].name;
        else
          this.$refs.seleccionar_archivo_nombre.innerHTML = '';
      }
      ,agregar_archivo() {
        if (this.$refs.seleccionar_archivo.files.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar un documento','error','alert');

        this.evidencias.push(this.$refs.seleccionar_archivo.files[0]);
        this.$refs.seleccionar_archivo.value = null;
        this.$refs.seleccionar_archivo_nombre.innerHTML = '';
      }
      ,obtener_extension(archivo) {
        let nombre = archivo.name;
        let partes = nombre.split('.');

        return partes[partes.length-1];
      }
      ,eliminar_archivo(index) {
        let evidencias = [];

        for(let i=0; i<this.evidencias.length; i++) {
          if(i != index)
            evidencias.push(this.evidencias[i]);
        }

        this.evidencias = evidencias;
      }
      ,async cargar_archivos() {
        try {
          if (this.evidencias.length == 0)
            return this.$helper.showMessage('Error','Debes de agregar al menos un archivo','error','alert');

          let payload = new FormData();

          this.evidencias.forEach(evidencia => {
            payload.append('archivos[]',evidencia);
          })

          let res = (await apiSolicitud.incidencias_evidencias(this.solicitud.id, this.incidencia.id, payload)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .body {
    max-height: 36vw !important;

    .card {
      .card-img-top {
        height: 150px;
        background-color: #CCCCCC;
        text-align: center;
        vertical-align: middle;

        span {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }

        img {
          max-width: 90%;
          max-height: 90%;
          vertical-align: middle;
        }
      }
    }
  }
</style>