<template>
  <div>
    <Modal @close="$emit('close')">
      <div class="title">Detalles</div>
      <div class="body">
        <div class="row form-group">
          <div class="col-sm-2">Tipo</div>
          <div class="col-sm-10">{{ incidencia.subtipo }}</div>
        </div>
        <div class="row form-group">
          <div class="col-sm-2">Descripción</div>
          <div class="col-sm-10">{{ incidencia.descripcion }}</div>
        </div>
        <div class="row form-group">
          <div class="col-sm-2">Comentarios finales</div>
          <div class="col-sm-10">
            <textarea v-model="comentarios_finales" name="comentarios_finales" id="comentarios_finales" cols="30" rows="10" class="form-control"></textarea>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-2">Evidencias</div>
          <div class="col-sm-10">
            <div class="row form-group">
              <div class="col-sm-12 text-right">
                <button class="btn btn-secondary mr-2" @click="modal_agregar_evidencia=true">Agregar evidencia</button>
              </div>
            </div>

            <div class="row">
              <div v-for="(evidencia, index) in incidencia.evidencias" :key="index" class="col-sm-4 mb-3">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title text-truncate">{{ evidencia.nombre_original }}</h5>
                    <div class="card-text">
                      <div>Tipo: {{ evidencia.extension }}</div>
                      <div>Peso: {{ $helper.bytesConverted(evidencia.peso) }}</div>
                    </div>
                    <div class="card-text text-right">
                      <small class="text-muted">
                        <a class="mr-2" href="#" @click.prevent="decargar_evidencia(evidencia)">Descargar</a>
                        <a href="#" @click.prevent="eliminar_evidencia(evidencia)">Eliminar</a>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-success mr-2" @click="modal_resolver=true">Cerrar incidencia</button>
            <button class="btn btn-primary mr-2" @click="guardar_cambios">Guardar cambios</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>

    <Evidencia v-if="modal_agregar_evidencia" :solicitud="solicitud" :incidencia="incidencia" @update="cerrar_acturalizar" @close="modal_agregar_evidencia=false" />
    <Eliminar v-if="modal_eliminar_evidencia" :solicitud="solicitud" :incidencia="incidencia" :evidencia="evidencia" @update="cerrar_acturalizar" @close="cancelar_eliminar_evidencia"  />
    <Resolver v-if="modal_resolver" :solicitud="solicitud" :incidencia="incidencia" @update="cerrar_todo_acturalizar" @close="modal_resolver=false"/>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  import Evidencia from './Evidencia'
  import Eliminar from './Eliminar'
  import Resolver from './Resolver'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    components: {
      Modal, Evidencia, Eliminar, Resolver
    }
    ,props: {
      catalogo: {
        type: Object
        ,default() {
          return {
            opciones: []
          }
        }
      }
      ,solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
      ,incidencia: {
        type: Object
        ,default() {
          return {
            subtipo: null
            ,descripcion: null
            ,created_at: null
            ,evidencias: []
          }
        }
      }
    }
    ,data() {
      return {
        modal_agregar_evidencia: false
        ,modal_eliminar_evidencia: false
        ,evidencia: {
          id: null
        }
        ,comentarios_finales: null
        ,modal_resolver: false
      }
    }
    ,mounted() {
      if (this.incidencia.comentarios_finales)
        this.comentarios_finales = this.incidencia.comentarios_finales;
    }
    ,methods: {
      buscar_nombre(valor) {
        let nombre;

        this.catalogo.opciones.forEach(opcion => {
          if (opcion.valor == valor)
            nombre = opcion.nombre
        })

        return nombre;
      }
      ,cerrar_acturalizar() {
        this.modal_agregar_evidencia = false;
        this.modal_eliminar_evidencia = false;
        this.$emit('update');
      }
      ,imagen_default(evidencia) {
        let imagenes = ['png','jpeg','jpg','gif','bmp'];
        let dominio;

        switch(process.env.VUE_APP_ENV) {
          case 'local':
            dominio = 'http://formalizacionbpb.local';
            break;
          case 'test':
            dominio = 'https://formalizacion.bienparabien.dev';
            break;
          case 'production':
            dominio = 'https://formalizacion.bienparabien.com/';
            break;
        }

        if (imagenes.indexOf(evidencia.extension) != -1) {
          return dominio+'/storage/'+evidencia.path;
        }

        return dominio+'/storage/images/documents.png';
      }
      ,obtener_extension(archivo) {
        let nombre = archivo.name;
        let partes = nombre.split('.');

        return partes[partes.length-1];
      }
      ,eliminar_evidencia(evidencia) {
        this.modal_eliminar_evidencia = true;
        this.evidencia = evidencia;
      }
      ,cancelar_eliminar_evidencia() {
        this.modal_eliminar_evidencia = false;
        this.evidencia = {
          id: null
        }
      }
      ,async decargar_evidencia(evidencia) {
        try {
          let res = (await apiSolicitud.incidencias_evidencias_descargar(this.solicitud.id,this.incidencia.id, evidencia.id)).data;

          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',evidencia.nombre_original);
          document.body.appendChild(link);
          link.click();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async guardar_cambios() {
        try {
          let payload = {
            incidencia_id: this.incidencia.id
            ,comentarios_finales: this.comentarios_finales
          };

          let res = (await apiSolicitud.incidencias_editar(this.solicitud.id, payload)).data;
          this.$log.info('res', res);

          this.$emit('update_close');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_todo_acturalizar() {
        this.modal_resolver = false;
        this.$emit('update_close');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .card {
    .card-img-top {
      height: 150px;
      background-color: #CCCCCC;
      text-align: center;
      vertical-align: middle;

      span {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      img {
        max-width: 90%;
        max-height: 90%;
        vertical-align: middle;
      }
    }
  }
</style>